<template>
  <div class="Start content min-h-screen">
    <div class="w-full h-2/5 relative header">
      <div class="logo">
        <Logo />
      </div>
    </div>
    <div class="p-12 w-full h-3/5 flex items-center m-auto">
      <div class="entrytext">
        <h1>
          {{ $t("start.title") }}<br>
          {{ $t("start.subtitle") }}
        </h1>
        <p class="mt-2 mb-8">
          {{ $t("start.text") }}
        </p>
        <go to="/question/1" class="button float-left mr-8">
          {{ $t("start.action") }}
        </go>
        <go to="/register" class="button float-left">
          {{ $t("start.action2") }}
        </go>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/images/UPD-Logo-White.svg';

export default {
  components: {
    Logo,
  },
  methods: {
    pushAnswer() {
      this.$store.state.array = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: absolute;
  left: 3.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.entrytext {
  max-width: 750px;
  margin: 0 auto;
  color: var(--darkgray);
}
.header {
  min-height: 40vh;
  background-image: url("../../assets/images/header.png?uri");
  background-size: 100%;
  background-repeat: no-repeat;
  @media only screen and (min-width: 1080px) {
    background-position: 0 -67px;
  }
}

</style>
